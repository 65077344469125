'use client'

// import Side from './Side'
// import Cover from './Cover'
import Simple from './Simple'
import { LAYOUT_TYPE_BLANK } from '@/constants/theme.constant'
import useStore from '@/store/useStore'
import { LayoutType } from '@/@types/theme'
import { ReactNode } from 'react'

interface AuthPagesLayoutProps {
    children: ReactNode
}

const AuthLayout = ({ children }: AuthPagesLayoutProps) => {
    const layoutType: LayoutType = useStore(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (state: any) => state.theme.layout.type,
    )

    return (
        <div className="app-layout-blank flex flex-auto flex-col h-[100vh]">
            {layoutType === LAYOUT_TYPE_BLANK ? (
                <>{children}</>
            ) : (
                 <Simple>{children}</Simple>
            )}
        </div>
    )
}

export default AuthLayout
