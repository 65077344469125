import { cloneElement } from 'react'
import Container from '@/components/shared/Container'
import Logo from '@/components/template/Logo'
import type { ReactNode, ReactElement } from 'react'
import type { CommonProps } from '@/@types/common'
interface SimpleProps extends CommonProps {
    content?: ReactNode
}

const Simple = ({ children, content, ...rest }: SimpleProps) => {
    return (
        <div className="h-full bg-white">
            <Container className="flex flex-col flex-auto items-center justify-center min-w-0 h-full">
                <div className="text-center mb-8">
                    <Logo type="full" mode="dark" imgClass="mx-auto" />
                </div>
                {/* <Card
                    className="min-w-[320px] md:min-w-[465px] border-0 bg-themeGray rounded-lg"
                    bodyClass="md:pt-7 pb-8 px-10"
                > */}
                {/* <div className="text-center">
                        <Logo type="streamline" imgClass="mx-auto" />
                    </div> */}
                <div className="text-center w-full">
                    {content}
                    {children
                        ? cloneElement(children as ReactElement, {
                              contentClassName: 'text-center',
                              ...rest,
                          })
                        : null}
                </div>
                {/* </Card> */}
            </Container>
        </div>
    )
}

export default Simple
